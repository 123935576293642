import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() ref = 'root';

  private state: Subscription;
  public showLoader: Boolean = false;

  constructor(private loaderService: SharedService) {}

  ngOnInit() {
    this.checkState();
  }

  checkState() {
    this.state = this.loaderService.stopSpinner().subscribe((res: boolean) => {
        setTimeout(() => (this.showLoader = res));
      });
  }

  ngOnDestroy() {
    this.state.unsubscribe();
  }
}
