import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { Howl } from 'howler';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class SharedService {
  private ResponceData = new BehaviorSubject('');
  public SelectedLocation = new BehaviorSubject('');
  private spinner = new BehaviorSubject(false);
  private createData = new BehaviorSubject('');
  private scanData = new BehaviorSubject('');
  private editSellerData = new BehaviorSubject([]);
  private editRoutingData = new BehaviorSubject([]);
  private editServiceabilityData = new BehaviorSubject([]);
  sound: any;

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  setResponce(responce: any) {
    this.ResponceData.next(responce);
  }
  getResponce(): Observable<any> {
    return this.ResponceData.asObservable();
  }
  setSelectedLocation(res: any) {
    this.SelectedLocation.next(res);
  }
  getSelectedLocation() {
    return this.SelectedLocation.asObservable();
  }
  setCreateData(res: any) {
    this.createData.next(res);
  }
  getCreateData() {
    return this.createData.asObservable();
  }
  setScanData(res: any) {
    this.scanData.next(res);
  }
  getScanData() {
    return this.scanData.asObservable();
  }

  startSpinner(res: boolean) {
    this.spinner.next(res);
  }
  stopSpinner() {
    return this.spinner.asObservable();
  }
  setEditSellerData(res) {
    this.editSellerData.next(res);
  }
  getEditSellerData() {
    return this.editSellerData.asObservable();
  }
  setEditRoutingData(res) {
    this.editRoutingData.next(res);
  }
  getEditRoutingData() {
    return this.editRoutingData.asObservable();
  }
  setEditeditServiceabilityData(res) {
    this.editServiceabilityData.next(res);
  }
  getEditeditServiceabilityData() {
    return this.editServiceabilityData.asObservable();
  }

  openSnackBar(message: string, action: string, customClass?: string, duration?: number) {
    this.playAudio(action);
    this._snackBar.open(message, 'X', {
      duration: duration || 4000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: customClass || ''
    });
  }

  handleErrorMsg = (msg, type) => {
    this.openSnackBar(msg, type, `${type.toLowerCase()}-snackbar`, 4000);
    this.startSpinner(false);
  }

  openSnackBarWithDifferentSound(message: string, action: string, customClass?: string, duration?: number) {
    this.playAudio('warning');
    this._snackBar.open(message, 'X', {
      duration: duration || 4000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: customClass || ''
    });
  }

  handleErrorMsgWithDifferentSound = (msg, type) => {
    this.openSnackBarWithDifferentSound(msg, type, `${type.toLowerCase()}-snackbar`, 4000);
    this.startSpinner(false);
  }

  playAudio(action) {
    switch (action.toLowerCase()) {
      case 'success':
        this.sound = new Howl({
          src: ['./../../../assets/success.wav']
        });
        break;
      case 'error':
        this.sound = new Howl({
          src: ['./../../../assets/error.wav']
        });
        break;
      case 'warning':
        this.sound = new Howl({
          src: ['./../../../assets/warning.wav']
        });
        break;
    }
    this.sound.play();
  }

  convertTitleCase(str, status?) {
    this.startSpinner(false);
    if (!str) {
      if (status === 0) {
        return 'API Failed';
      }
      if (status) {
        return this.getMessageBasedONStatusCode(status);
      }
    }
    return str.toLowerCase().split(' ').map(function(word) {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  }

  convertTitle(str, status?){
    this.startSpinner(false);
    if (!str) {
      if (status === 0) {
        return 'API Failed';
      }
      if (status) {
        return this.getMessageBasedONStatusCode(status);
      }
    }
    return str;
  }

  getMessageBasedONStatusCode(status) {
    if (status === 403 || status === 401) {
      return 'User Unauthorized';
    } else {
      return `Unable To Parse Error Message, Status Code is ${status}`;
    }
  }
}

export const StatusMapping = {
  'INT-PKG-MANF': 'Manifested',
  'X-PIOM': 'GI Scan',
  'INT-KYC-REQ': 'KYC Requested',
  'INT-KYC-RCV': 'KYC Received',
  'INT-KYC-F': 'KYC-Failed',
  'INT-KYC-INV': 'KYC Invalid',
  'INT-KYC-V': 'KYC Validated',
  'INT-HAWB': 'HAWB Created',
  'INT-BAG': 'Bag Created',
  'INT-MBAG': 'MAWB Created',
  'INT-CUSCL': 'Customs Cleared',
  'INT-CUSHL': 'Held at Customs',
  'DLYRG-149': 'Customs Cleared',
  'DLYRG-150': 'Held at Customs',
  'CB-101': 'Shipment Abandoned in Customs',
  'DLYDC-102': 'Natural Disaster',
  'DLYRG-125': 'Delay due to Disturbance/Strike',
  'CB-104': 'Flight Departed',
  'DLYLH-106': 'Flight Delayed',
  'DLYLH-104': 'Flight Cancelled',
  'CB-105': 'Flight Arrived',
  'DLYRG-151': 'Cleared by Customs',
  'CB-102': 'Natural Disaster',
  'CB-103': 'International Shipments Unlock',
  'CF-105': 'Flight Arrived',
  'DLYDC-125': 'Delay due to Disturbance/Strik',
  'DLYDC-132': 'Delay due to Infra issues(IT, Power, HQ, Dock)',
  'DLYDC-149': 'Customs Cleared',
  'DLYDC-150': 'Held by Customs',
  'INT-INC': 'Incoming Done',
  'INT-LOCK': 'International Shipments Locked',
  'INT-UNLOCK': 'International Shipments Unlock',
  'INT-RET': 'International Shipment Returned',
  'DLYLH-133': 'Air Offload - Capacity constra',
  'CB-106': 'Handed to Customs',
  'CB-107': 'Examination Cleared',
  'DLYDC-151': 'Cleared by Customs',
  'CB': 'Received at Gateway',
  'DLYDC-104': 'Flight Cancelled'
};

export function getPackageStatusAndLocation(pkg) {
  let data: [ string ];
  let statusCode;
  if ( pkg._source ){
    statusCode = pkg._source.cs.sf.nsl;
  } else if (pkg && pkg.cs && pkg.cs.sf) {
    statusCode = pkg.cs.sf.nsl;
  } else {
    statusCode = pkg.cs.nsl;
  }
  data = StatusMapping[ statusCode ];
  return data;
}

export const center_list = {
  prod: [
    {value: 'INKAALKL', name: 'Bangalore_XB_INT'},
    {value: 'CN518103A1A', name: 'CN_Shenzen_P'},
    {value: 'LKWEAAAX', name: 'Colombo_Airport'},
    {value: 'INDLAAES', name: 'DEL_Airport'},
    {value: 'INDLAAEZ', name: 'Delhi_Bamnoli_INT'},
    {value: 'INDLAAET', name: 'Delhi_Customs'},
    {value: 'IND110037AAH', name: 'Delhi_Gateway'},
    {value: 'BDDKAAAW', name: 'Dhaka_Airport'},
    {value: 'CNHKAAAC', name: 'HKG_Airport'},
    {value: 'CNHKAAAB', name: 'HKG_Gateway'},
    {value: 'CNHKAAAD', name: 'HKG_KwaiChung_Gateway'},
    {value: 'INMHABDJ', name: 'Mumbai_XB_INT'},
    {value: 'USNYAAAB', name: 'US_NewYork_GW'},
    {value: 'USILAMLL', name: 'US_Chicago_GW'}
  ],
  dev: [
    {value: 'IND400093AAA', name: 'CN_Shenzen_P'},
    {value: 'INHRABCM', name: 'DEL_Airport'},
    {value: 'INDLAAEZ', name: 'Delhi_Bamnoli_INT'},
    {value: 'INHRABCN', name: 'Delhi_Customs'},
    {value: 'IND110037AAA', name: 'Delhi_Gateway'},
    {value: 'INHRABCL', name: 'HKG_Airport'},
    {value: 'CNHKAAAB', name: 'HKG_Gateway'}
  ]
}
