import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/';
import { RouterModule } from '@angular/router';
import { BarcodeGenDirective } from './directives/barcode-gen.directive';
import { DlvAutofocus } from './directives/autoFocus.directive';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { PermissionDirective } from './directives/permission.directive';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TranslateModule, HttpClientModule, MatSnackBarModule],
  declarations: [LoaderComponent, BarcodeGenDirective, DlvAutofocus, NumbersOnlyDirective, PermissionDirective],
  exports: [LoaderComponent, BarcodeGenDirective, DlvAutofocus, TranslateModule, HttpClientModule, NumbersOnlyDirective, PermissionDirective],
})
export class SharedModule {}
