import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreRoutesModule } from './core.routes';
import { SearchDataService } from '../dashboard/searchData/search-data.service';
import { DlvNgAuthModule } from 'dlv-ng-auth';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '@app/shared/shared.module';
import {
  LayoutComponent,
  SidebarComponent,
  ForbiddenComponent,
  LoginComponent
} from './components';
import { ClickOutsideDirective } from '../shared/directives/click-out-side.directive';

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    ForbiddenComponent,
    LoginComponent,
    ClickOutsideDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    CoreRoutesModule,
    MatSelectModule,
    MatInputModule,
    SharedModule,
    MatSnackBarModule,
    DlvNgAuthModule
  ],
  providers: [SearchDataService],
  entryComponents: []
})
export class CoreModule {}
