import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, AuthService } from 'dlv-ng-auth';
import { SharedService } from '../../../shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENT_PERMISSION } from '../../../constants/permission.constants';
import { CheckPermissionService } from './../../services/check-permission.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  // currentRoute: any = document.location.href;
  isData: boolean;
  isScanData: boolean;
  isDropdownActive: boolean;
  isSellerDropdownActive: boolean;
  isConsigneeDropdownActive: boolean;
  isServiceabilityDropdownActive: boolean;
  isRoutingDropdownActive: boolean;
  isManifestDropdownActive: boolean;
  isPurDropdownActive: boolean;
  permissionList: string[];
  COMPONENT_PERMISSION = COMPONENT_PERMISSION;
  userProfileAvatar: any;
  userProfie: any;
  userFirstName;
  userLastName;
  userName: string;
  public avatarBackgroundcolor;
  isAmazonSellerDropdownActive: boolean;
  isPickupPincodeDropdownActive: boolean;

  @Output() messageEvent = new EventEmitter<any>();

  constructor(
    private router: Router,
    private userServices: UserService,
    private authService: AuthService,
    private service: SharedService,
    private translate: TranslateService,
    private checkPermissionService: CheckPermissionService
  ) {
    const userDetails = this.userServices.getUserFromJwt();
    this.userProfie =
        userDetails['first_name'] && userDetails['last_name']
            ? userDetails['first_name'] + ' ' + userDetails['last_name']
            : 'No name';
    this.userFirstName = userDetails['first_name'];
    this.userLastName = userDetails['last_name'];
    this.userProfileAvatar =
        userDetails['first_name'] && userDetails['last_name']
            ? userDetails['first_name'][0] + userDetails['last_name'][0]
            : userDetails['first_name'][0] + userDetails['first_name'][1];
    this.avatarBackgroundcolor = this.userServices.getBackgroundColor(
      this.userProfie
    );
    localStorage.setItem('UserName', this.userProfie);
  }

  ngOnInit() {
    // this.router.events.subscribe(data => {
    //   this.currentRoute = data['url'];
    // });
    this.isDropdownActive = false;
    this.isSellerDropdownActive = false;
    this.isConsigneeDropdownActive = false;
    this.isAmazonSellerDropdownActive = false;
    this.isServiceabilityDropdownActive = false;
    this.isRoutingDropdownActive = false;
    this.isManifestDropdownActive = false;
    this.service.getCreateData().subscribe(
      res => {
        if (res === '') {
          this.isData = false;
        } else {
          this.isData = true;
        }
      }
    );

    this.service.getScanData().subscribe(
      res => {
        if (res === '') {
          this.isScanData = false;
        } else {
          this.isScanData = true;
        }
      }
    );

    this.permissionList = JSON.parse(localStorage.getItem('scopes'));
  }

  checkPermissionExist(permissionList) {
    let isPermitted = this.checkPermissionService.checkPermission(permissionList);
    return isPermitted;
  }

  createCheck() {
    const msg = this.translate.instant('sidebar.sure')
    if (this.isData && this.router.url.includes('create')) {
      if (confirm(msg)) {
      } else {
        this.router.navigate(['/dashboard/create']);
      }
    }
    
    if (this.isScanData && this.router.url.includes('inscan')) {
      if (confirm(msg)) {
      } else {
        this.router.navigate(['/dashboard/inscan']);
      }
    }
    this.messageEvent.emit();
  }  

  onLogout() {
    this.authService.logout();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  onToggle() {
    this.isDropdownActive = !this.isDropdownActive;

    this.isSellerDropdownActive = false;
    this.isServiceabilityDropdownActive = false;
    this.isRoutingDropdownActive = false;
    this.isPurDropdownActive = false;
  }

  onSellerToggle() {
    this.isSellerDropdownActive = !this.isSellerDropdownActive;

    this.isDropdownActive = false;
    this.isServiceabilityDropdownActive = false;
    this.isRoutingDropdownActive = false;
    this.isPurDropdownActive = false;
  }

  onConsigneeToggle() {
    this.isConsigneeDropdownActive = !this.isConsigneeDropdownActive;
    this.isDropdownActive = false;
    this.isServiceabilityDropdownActive = false;
    this.isRoutingDropdownActive = false;
    this.isPurDropdownActive = false;
  }

  onServiceabilityToggle() {
    this.isServiceabilityDropdownActive = !this.isServiceabilityDropdownActive;

    this.isDropdownActive = false;
    this.isSellerDropdownActive = false;
    this.isRoutingDropdownActive = false;
    this.isPurDropdownActive = false;
  }
  
  onRoutingToggle() {
    this.isRoutingDropdownActive = !this.isRoutingDropdownActive;

    this.isDropdownActive = false;
    this.isSellerDropdownActive = false;
    this.isServiceabilityDropdownActive = false;
    this.isPurDropdownActive = false;
  }

  onManifestToggle() {
    this.isManifestDropdownActive = !this.isManifestDropdownActive;

    this.isDropdownActive = false;
    this.isSellerDropdownActive = false;
    this.isServiceabilityDropdownActive = false;
    this.isPurDropdownActive = false;
  }

  onAmazonSellerToggle() {
    this.isAmazonSellerDropdownActive = !this.isAmazonSellerDropdownActive;

    this.isDropdownActive = false;
    this.isServiceabilityDropdownActive = false;
    this.isRoutingDropdownActive = false;
    this.isPurDropdownActive = false;
  }


  onPickupPincodeToggle() {
    this.isPickupPincodeDropdownActive = !this.isPickupPincodeDropdownActive;

    this.isDropdownActive = false;
    this.isServiceabilityDropdownActive = false;
    this.isRoutingDropdownActive = false;
    this.isPurDropdownActive = false;
  }


  onPurToggle(){
    this.isPurDropdownActive = !this.isPurDropdownActive;

    this.isDropdownActive = false;
    this.isServiceabilityDropdownActive = false;
    this.isRoutingDropdownActive = false;
    this.isManifestDropdownActive = false;
  }
}
