import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import { SharedService } from './../services/shared.service';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {
  @Input('appPermission') permissionName;
  userPermissions: any;

  constructor(
    private _elementRef: ElementRef,
    private service: SharedService
  ) { }

  ngOnInit() {
    this.checkPermission();
  }

  checkPermission() {
    let isPermitted;
    this.userPermissions = JSON.parse(localStorage.getItem('scopes'));

    if (this.permissionName) {
      isPermitted = true;
      for (let permissionIndex = 0; permissionIndex < this.permissionName.length; permissionIndex++) {
        if (this.userPermissions.indexOf(this.permissionName[permissionIndex]) === -1) {
          isPermitted = false;
          break;
        }
      }
    }

    if (isPermitted) {
      //do nothing if permission is there
    } else {
      let el: HTMLElement = this._elementRef.nativeElement;
      let parentNode = el.parentNode;
      parentNode.removeChild(el);
    }
  }
}
