import { Component, OnInit } from '@angular/core';
import { AuthService } from 'dlv-ng-auth';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private translate: TranslateService
  ) {
    authService.init({
      environment: environment.env
    });
    translate.setDefaultLang('en');
  }

  ngOnInit() {
  }
}
