export const PERMISSION = {
    'can_get_bag_info': '^\\/aggregate\\/bag\\/.+\\:GET$',
    'can_create_mbag': '^\\/aggregate\\/batchAddBagToMBag\\:POST$',
    'can_create_bag': '^\\/aggregate\\/batchAddHAWBToBag\\:POST$',
    'can_create_hawb': '^\\/aggregate\\/batchAddPackageToHAWB\\:POST$',
    'can_get_bulk_scan_info': '^\\/aggregate\\/bulkScan\\/.+\\:GET$',
    'can_do_bulk_scan': '^\\/aggregate\\/bulkScan\\:POST$',
    'can_perform_gi_scan': '^\\/aggregate\\/giscan\\:POST$',
    'can_get_hawb_documents': '^\\/aggregate\\/hawb\\/.+\\/documents\\:GET$',
    'can_get_hawb_info': '^\\/aggregate\\/hawb\\/.+\\:GET$',
    'can_get_location_config': '^\\/aggregate\\/locationConfig\\:GET$',
    'can_get_mbag_info': '^\\/aggregate\\/mbag\\/.+\\:GET$',
    'can_create_pbag': '^\\/aggregate\\/pbag\\:POST$',
    'can_search_aggregate_listing': '^\\/aggregate\\/search\\:GET$',
    'can_validate_shipment': '^\\/aggregate\\/validate\\:POST$',
    'can_manifest_package_bulk': '^\\/package\\/batchGeneratePackages\\:POST$',
    'can_fetch_clients': '^\\/package\\/clients\\:GET$',
    'get_client_info_by_id': '^\\/package\\/clients\\/.+\\:GET$',
    'can_onboard_client': '^\\/package\\/clients\\:POST$',
    'can_onboard_consignee': '^\\/package\\/consigneeInfo\\:POST$',
    //'can_fetch_consignee_info': '^\\/package\\/consigneeInfo\\:GET$',
    'get_package_invoice': '^\\/package\\/.+\\/invoice\\:GET$',
    'can_fetch_routing': '^\\/package\\/routing\\:GET$',
    'can_add_routing': '^\\/package\\/routing\\:POST$',
    'can_view_facility_listing': '^\\/package\\/search\\/\\_search\\:POST$',
    'can_fetch_seller_info': '^\\/package\\/sellerInfo\\:GET$',
    'can_add_seller_info': '^\\/package\\/sellerInfo\\:POST$',
    'can_add_serviceability': '^\\/package\\/serviceability\\:POST$',
    'can_get_shipping_label': '^\\/package\\/.+\\/shipping-label\\:GET$',
    'package_track_api': '^\\/package\\/track\\/.+\\:GET$',
    'can_fetch_serviceability': '^\\/package\\/serviceability\\:GET$',
    'can_fetch_countries': '^\\/package\\/countries\\:GET$',
    'can_decrypt_name_address': '^\\/resources\\/decrypt\\/name\\:POST$',
    'can_download_seller_kyc': '^\\/package\\/sellerInfo\\/.+\\/kyc\\:GET$',
    'can_upload_seller_kyc': '^\\/package\\/sellerInfo\\/.+\\/kyc\\:POST$',
    'can_download_package_document': '^\\/package\\/.+\\/download\\:GET$',
    'can_update_seller_info': '^\\/package\\/sellerInfo\\/.+\\:PUT$',
    'can_delete_seller_info': '^\\/package\\/sellerInfo\\/.+\\:DELETE$',
    'can_update_serviceability': '^\\/package\\/serviceability\\/.+\\:PUT$',
    'can_delete_serviceability': '^\\/package\\/serviceability\\/.+\\:DELETE$',
    'can_update_routing': '^\\/package\\/routing\\:PUT$',
    'can_delete_routing': '^\\/package\\/routing\\:DELETE$',
    'can_upload_boe_doc': '^\\/package\\/document\\:POST$',
    'can_upload_package_document': '^\\/package\\/.+\\/upload\\:POST$',
    'can_perform_handover_scan': '^\\/aggregate\\/ho-scan\\:POST$',
    'can_download_package_documents_bulk': '^\\/package\\/document\\/bulk\\:POST$',
    'can_update_package_dimensions': '^\\/package\\/bulk\\/edit-dims\\:POST$',
    'can_fetch_clients_info': '^\\/package\\/fetch_clients_info\\:GET$',
    'fetch_amazon_seller_info': '^\\/package\\/sellerInfo\\/registerInfo\\:GET$',
    'can_download_amazon_seller_document' : '^\\/package\\/sellerInfo\\/document\\:GET$',
    'can_bulk_update_sf_weight': '^\\/package\\/bulk\\/edit-dims\\:POST$',
    'can_create_pickup_pincode': '^\\/pkg\\/pincodes\\:POST$',
    'can_update_pincode_routing': '^\\/package\\/pincode_routing\\:PUT$',
    'can_download_kyc_documents': '^\\/kyc\\/kycDocumentDownloadBatch\\:POST$',
    'can_create_pickup_request': '^\\/package\\/pickup\\:POST$',
    'can_fetch_pickup_details': '^\\/package\\/pickup\\:GET$',
    'can_get_package_boe': '^\\/package\\/.+\\/boe\\:GET$',
    'can_get_hawb_label': '^\\/aggregate\\/hawb\\/.+\\/labels\\:GET$',
    'can_get_hawb_prealert': '^\\/aggregate\\/mbag\\/.+\\/documents\\:GET$'
}

export const COMPONENT_PERMISSION = {
    'inscan': [PERMISSION.can_perform_gi_scan, PERMISSION.can_create_pbag, PERMISSION.can_get_location_config],
    'create': [PERMISSION.can_validate_shipment, PERMISSION.can_view_facility_listing, PERMISSION.can_create_bag, PERMISSION.can_create_hawb, PERMISSION.can_create_mbag, PERMISSION.can_get_location_config],
    'apply-NSL': [PERMISSION.can_do_bulk_scan, PERMISSION.can_get_location_config],
    'status-check': [PERMISSION.can_get_bulk_scan_info],
    'manifest': [PERMISSION.can_manifest_package_bulk],
    'client-onboard': [PERMISSION.can_onboard_client],
    'seller-onboard': [PERMISSION.can_fetch_clients, PERMISSION.can_fetch_countries, PERMISSION.can_add_seller_info, PERMISSION.can_upload_seller_kyc],
    'consignee-onboard': [PERMISSION.can_onboard_consignee],
    //'fetch-consignee': [PERMISSION.can_fetch_consignee_info],
    'fetch-seller': [PERMISSION.can_fetch_seller_info, PERMISSION.can_fetch_clients],
    'serviceability': [PERMISSION.can_fetch_countries, PERMISSION.can_add_serviceability],
    'fetch-serviceability': [PERMISSION.can_fetch_serviceability, PERMISSION.can_fetch_countries],
    'routing': [PERMISSION.can_fetch_countries, PERMISSION.can_add_routing],
    'fetch-routing': [PERMISSION.can_fetch_routing, PERMISSION.can_fetch_countries],
    'can-download-shipping-label': [PERMISSION.can_get_shipping_label],
    'can-download-auth-letter': [PERMISSION.can_download_package_document],
    'can-download-package-invoice': [PERMISSION.get_package_invoice],
    'can-download-package-boe': [PERMISSION.can_get_package_boe],
    'can-download-package-hawb': [PERMISSION.can_download_package_document],
    'can-download-package-pod': [PERMISSION.can_download_package_document],
    'can-download-bag-info': [PERMISSION.can_get_bag_info],
    'can-download-hawb-label': [PERMISSION.can_get_hawb_label],
    'can-download-hawb-invoice': [PERMISSION.can_get_hawb_documents],
    'can-download-mawb-label': [PERMISSION.can_get_mbag_info],
    'can-download-mawb-prealert': [PERMISSION.can_get_hawb_prealert],
    'document-upload': [PERMISSION.can_upload_boe_doc, PERMISSION.can_upload_package_document],
    'document-download': [],
    'facility': [PERMISSION.can_search_aggregate_listing, PERMISSION.package_track_api, PERMISSION.can_get_bag_info, PERMISSION.can_get_hawb_info, PERMISSION.can_get_hawb_documents, PERMISSION.can_get_mbag_info],
    'print': [PERMISSION.can_get_hawb_info, PERMISSION.can_get_hawb_info, PERMISSION.can_get_mbag_info],
    'shipments-in-facility': [PERMISSION.can_search_aggregate_listing, PERMISSION.package_track_api],
    'packages-in-bag': [PERMISSION.can_get_bag_info],
    'bags-or-packages-in-hawb': [PERMISSION.can_get_hawb_info],
    'bags-or-hawb-in-mawb': [PERMISSION.can_get_mbag_info],
    'searchdata': [PERMISSION.can_search_aggregate_listing],
    'edit-seller': [PERMISSION.can_update_seller_info, PERMISSION.can_upload_seller_kyc],
    'delete-seller': [PERMISSION.can_delete_seller_info],
    'edit-serviceability': [PERMISSION.can_update_serviceability],
    'delete-serviceability': [PERMISSION.can_delete_serviceability],
    'edit-routing': [PERMISSION.can_update_routing],
    'delete-routing': [PERMISSION.can_delete_routing],
    'download-seller-kyc': [PERMISSION.can_download_seller_kyc],
    'handover': [PERMISSION.can_perform_handover_scan, PERMISSION.can_get_location_config],
    'bulk-download': [PERMISSION.can_download_package_documents_bulk],
    'fetch_amazon_seller_info': [PERMISSION.fetch_amazon_seller_info],
    'download-amazon-seller-document': [PERMISSION.can_download_amazon_seller_document],
    'update-dimension': [PERMISSION.can_bulk_update_sf_weight],
    'create-pickup-pincode': [PERMISSION.can_create_pickup_pincode],
    'update-routing': [PERMISSION.can_update_pincode_routing],
    'kyc-document-download': [PERMISSION.can_download_kyc_documents],
    'create-pickup': [PERMISSION.can_create_pickup_request],
    'fetch-pickup': [PERMISSION.can_fetch_pickup_details],
    'view-consignee-name-address': [PERMISSION.can_decrypt_name_address]
}
