import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Router,
  Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from 'dlv-ng-auth';
import { CheckPermissionService } from './check-permission.service';
import { COMPONENT_PERMISSION } from './../../constants/permission.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {
  COMPONENT_PERMISSION = COMPONENT_PERMISSION;

  constructor(
    private auth: AuthService,
    private router: Router,
    private checkPermissionService: CheckPermissionService
  ) { }

  canLoad(route: Route): boolean {
    return this.checkLogin(`/${route.path}`);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.checkLogin(state.url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.checkLogin(state.url);
  }

  private checkLogin(url: string): boolean {
    const isLoggedIn = this.auth.isAuthenticated();
    if (url === '/login' && isLoggedIn) {
      this.navigateTo('dashboard');
      return true;
    } else if (url === '/login' && !isLoggedIn) {
      return true;
    } else if (isLoggedIn) {
      return true;
    } else {
      this.navigateTo('login');
      return false;
    }
  }

  private navigateTo(route: string) {
    this.router.navigate([ `/${route}` ], { replaceUrl: true });
  }

  checkRoutingList(url) {
    if (url.includes('print')) {
      const value = url.split('/')[1];
      this.checkPermissionExist(COMPONENT_PERMISSION[value]);
    } else {
      if (!url.includes('facility')) {
        const value = url.split('/')[2];
        const subUrl = value.split(';')[0];
        if (subUrl) {
          this.checkPermissionExist(COMPONENT_PERMISSION[subUrl]);
          return;
        }
        this.checkPermissionExist(COMPONENT_PERMISSION[value]);
      }
    }
  }

  checkPermissionExist(permissionList) {
    let isPermitted = this.checkPermissionService.checkPermission(permissionList);
    if (!isPermitted) {
      this.navigateTo('facility');
    }
  }
}
