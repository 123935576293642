import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[dlvAutofocus]'
})
// tslint:disable-next-line: directive-class-suffix
export class DlvAutofocus implements OnInit {
  @Input() isFocus: boolean;

  private el: any;
  constructor(
    private elementRef: ElementRef,
  ) {
      this.el = this.elementRef.nativeElement
  }

  ngOnInit() {
    this.el.focus();
  }
}
