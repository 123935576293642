// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

declare var require: any;

export const APIURL_TRACK = 'https://api-stage-starfleet.delhivery.com/package';
export const CREATEAPIURL = 'https://api-stage-starfleet.delhivery.com/aggregate';
export const DECRYPTURL = 'https://api-stage-starfleet.delhivery.com/resources/decrypt/';
export const APIURL_PKG = 'https://api-stage-starfleet.delhivery.com/pkg';
export const K8_APIURL_TRACK = 'https://api-stage-sf.pntrzz.com'

export const KYC_API = 'https://api-stage-starfleet.delhivery.com/kyc';

export const UMS_SCOPES_URL = 'https://api-stage-ums.delhivery.com/user_roles/self/';

export const UMS_URL = 'https://api-stage-ums.delhivery.com';
export const KYC_K8S_URL = "https://api-stage-sf.pntrzz.com/kyc";

export const APP_ID = 866;
export const APP_NAME = 'StarFleet';

export const DEFAULT_CARRIER_ACCOUNT_VALUE = {
  ups: '5Y0025',
  aramex: 'GGN10329'
};

export const DEFAULT_CARRIER_ACCOUNT_COMMERCIAL_VALUE = {
  ups: '5Y0025',
  aramex: 'GGN10323'
};
export const environment = {
  production: false,
  env: 'dev',
  version: require('../../package.json').version
};
