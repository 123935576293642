import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { APP_ID, environment } from '@env/environment';

@Injectable()
export class SearchDataService {
   constructor(private http: HttpClient) { }

   getCookie(name) {
      let nameEQ = name + '=';
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0)
              return c.substring(nameEQ.length, c.length);
      }
      return null;
  }

   getAuthKey() {
      let key = '';
      if (environment.env === 'dev') {
         key = this.getCookie('dev_ums_token');
      } else if (environment.env === 'prod') {
         key = this.getCookie('ums_token');
      }
      return key;
   }

   // getIdToken() {
   //    const id_token = localStorage.getItem('StarFleet_IDT');
   //    return id_token;
   // }

   getAuthHeader(body?) {
      const key = this.getAuthKey();
      // const id_token = this.getIdToken();
      const httpOptions = {
         headers: {
            Authorization: `Bearer ${key}`
         }
      };
      if (body) {
         httpOptions['body'] = body
      }
      return httpOptions;
   }

   getUMSAuthHeader() {
      const key = this.getAuthKey();
      const httpOptions = {
         headers: {
            Authorization: `Bearer ${key}`,
            'X-App-Id': `${APP_ID}`
         }
      };
      return httpOptions;
   }

   getFileUploadHeader(file) {
      const key = this.getAuthKey();
      // const id_token = this.getIdToken();
      const header = new HttpHeaders({
         'Authorization': `Bearer ${key}`,
         // 'id_token': id_token,
         'Content-Type': file.type
      });
      const httpOptions = {
         headers: header
      };
      return httpOptions;
   }

   getAuthHeaderAccept(type) {
      const key = this.getAuthKey();
      // const id_token = this.getIdToken();
      const header = new HttpHeaders({
         'Authorization': `Bearer ${key}`,
         // 'id_token': id_token,
         'Accept': type});
      const httpOptions = {
         headers: header,
         responseType: 'arraybuffer' as 'json'
      };
      return httpOptions;
   }

   postData(url, parms?: any, options?: any) {
      const httpOptions = this.getAuthHeader();
      return this.http.post(url, parms, {...httpOptions, ...options});
   }

   deleteData(url, parms?: any) {
      let httpOptions;
      if (parms) {
         httpOptions = this.getAuthHeader(parms);
      } else {
         httpOptions = this.getAuthHeader();
      }
      return this.http.request('DELETE', url, httpOptions);
   }

   putData(url, parms?: any) {
      const httpOptions = this.getAuthHeader();
      return this.http.put(url, parms, httpOptions);
   }

   postFileData(url, file?: any) {
      const httpOptions = this.getFileUploadHeader(file);
      return this.http.post(url, file,httpOptions);
   }

   putFileData(url, file?: any) {
      return this.http.put(url,file);
   }

   putBulkFileData(url, file?: any){
      const httpOptions = this.getFileUploadHeader(file);
      return this.http.put(url,file, httpOptions);
   }

   getDownloadData(url) {
      const httpOptions = this.getAuthHeaderAccept('application/pdf, application/zip');
      httpOptions['observe'] = "response";
      return this.http.get<any>(url, httpOptions);
   }

   getSellerDownloadData(url) {
      const httpOptions = this.getAuthHeaderAccept('application/pdf, image/png, image/jpg, image/jpeg');
      httpOptions['observe'] = "response";
      return this.http.get<any>(url, httpOptions);
   }

   getData(url) {
      const httpOptions = this.getAuthHeader();
      return this.http.get(url, httpOptions);
   }

   getIpAddress() {
      return this.http
         .get('https://ipapi.co/json')
         .map(response => response || {})
         .catch(this.handleError);
   }

   putRequest(url, Binarydata, headers) {
      return this.http.put(url, Binarydata, { headers });
   }

   fetchPresignedUrl(url, data?: any) {
      const key = this.getAuthKey();
      // const id_token = this.getIdToken();
      const header = new HttpHeaders({
         'Authorization': 'Bearer ' + key,
         // 'id_token': id_token,
         'Content-Type': 'application/json'
      });
      const httpOptions = {
         headers : header
      };
      return this.http.post(url, data, httpOptions);
   }

   private handleError(error: HttpErrorResponse): Observable<any> {
      return Observable.throw(error);
   }

   getCsvDownloadData(url) {
      const key = this.getAuthKey();
      // const id_token = this.getIdToken();
      const header = new HttpHeaders({
         'Authorization': `Bearer ${key}`,
         // 'id_token': id_token,
         'Accept': 'text/csv'});
      const httpOptions = {
         headers: header,
         responseType: 'arraybuffer' as 'json'
      };

      httpOptions['observe'] = "response";
      return this.http.get<any>(url, httpOptions);
   }

   getUserData(url) {
      const httpOptions = this.getUMSAuthHeader();
      return this.http.get(url, httpOptions);
   }

}
