import { SharedService } from './../../shared/services/shared.service';
import { Injectable } from '@angular/core';
// import { DlvAuth0Service } from 'dlv-ng-auth';

@Injectable({
  providedIn: 'root'
})
export class CheckPermissionService {
  userPermissions: any;

  constructor(
    private service: SharedService,
    // private dlvAuthService: DlvAuth0Service,
  ) { }

  checkPermission(permissionName) {
    let isPermitted;

    // Get App Permission
    // this.dlvAuthService.getAppPermission(
    //   (scopes: Array<string | null>) => {
    //     localStorage.setItem('scopes', JSON.stringify(scopes));
    //   }
    // );
    this.userPermissions = JSON.parse(localStorage.getItem('scopes'));

    if (permissionName) {
      isPermitted = true;
      for (let permissionIndex = 0; permissionIndex < permissionName.length; permissionIndex++) {
        if (this.userPermissions.indexOf(permissionName[permissionIndex]) === -1) {
          isPermitted = false;
          break;
        }
      }
    }
    return isPermitted;
    return true;
  }
}
